// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDEhxBIFg4UORjfEt94IIW28qc7P7QBBNU",
  authDomain: "gannamas-9e3ac.firebaseapp.com",
  projectId: "gannamas-9e3ac",
  storageBucket: "gannamas-9e3ac.appspot.com",
  messagingSenderId: "944754777845",
  appId: "1:944754777845:web:9e25895ed11970f67fafbc",
  measurementId: "G-ERWQ0C6NBQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);